body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  background-color: #1e1e1e;
}

/* fix the direction of arrows inside MUI calendar */
.MuiDateCalendar-root .MuiPickersArrowSwitcher-root svg {
  transform: rotate(180deg);
  color: white;
}

.MuiDateCalendar-root .Mui-disabled svg {
  color: black;
}

.MuiPickersCalendarHeader-switchViewButton svg {
  color: white;
}
